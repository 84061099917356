import Swal from "sweetalert2";
<template>
    <transition name="fade-in-up">
        <v-app>
            <v-card>
                <v-card-title>
                    Mi perfil
                </v-card-title>
                <v-progress-linear v-if="loading"
                                   indeterminate
                ></v-progress-linear>
                <v-card v-else>
                    <v-toolbar flat color="primary" dark>
                        <v-toolbar-title>{{user.email}}</v-toolbar-title>
                    </v-toolbar>
                    <v-tabs vertical>
                        <v-tab>
                            <v-icon left>mdi-account</v-icon>
                            Información general
                        </v-tab>
                        <v-tab>
                            <v-icon left>mdi-lock</v-icon>
                            Cambio de contraseña
                        </v-tab>
                        <v-tab>
                            <v-icon left>mdi-delete</v-icon>
                            Deshabilitar mi perfil
                        </v-tab>
                        <v-tab-item>
                        <user-form :postPayment="postPayment"></user-form>

                        </v-tab-item>
                        <v-tab-item>
                            <v-row>
                                <v-col cols="2"></v-col>
                                <v-col cols="6">
                                    <v-card flat>
                                        <b-form class="kt-form" @submit.stop.prevent="submitPassword">
                                            <b-form-group
                                                    id="profile-old-password"
                                                    label="Ingrese contraseña actual"
                                                    label-for="profile-old-password">
                                                <b-form-input
                                                        type="password"
                                                        id="profile-old-password"
                                                        name="profile-old-password"
                                                        v-model="$v.formPassword.oldPassword.$model"
                                                        :state="validateStatePassword('oldPassword')"
                                                        aria-describedby="profile-old-password-live-feedback"
                                                        placeholder="Password"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="profile-old-password-live-feedback">
                                                    Password actual es requerido.
                                                </b-form-invalid-feedback>
                                            </b-form-group>
                                            <b-form-group
                                                    id="profile-password"
                                                    label="Ingrese la nueva contraseña"
                                                    label-for="example-input-2">
                                                <b-form-input
                                                        type="password"
                                                        id="profile-password"
                                                        name="profile-password"
                                                        v-model="$v.formPassword.password.$model"
                                                        :state="validateStatePassword('password')"
                                                        aria-describedby="profile-password-live-feedback"
                                                        placeholder="Password"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="profile-password-live-feedback">
                                                    Password no válido.
                                                </b-form-invalid-feedback>
                                            </b-form-group>

                                            <b-form-group
                                                    id="profile-cpassword"
                                                    label="Confirmación de password"
                                                    label-for="profile-cpassword">
                                                <b-form-input
                                                        type="password"
                                                        id="profile-cpassword"
                                                        name="profile-cpassword"
                                                        v-model="$v.formPassword.c_password.$model"
                                                        :state="validateStatePassword('c_password')"
                                                        aria-describedby="profile-cpassword-live-feedback"
                                                        placeholder="Confirmación de password"
                                                ></b-form-input>

                                                <b-form-invalid-feedback id="profile-cpassword-live-feedback">
                                                    Confirmación de password debe conincidir.
                                                </b-form-invalid-feedback>
                                            </b-form-group>

                                            <!--begin::Action-->
                                            <div class="kt-login__actions">
                                                <b-button
                                                        type="submit"
                                                        id="kt_submit_p"
                                                        class="btn btn-primary btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right pull-right"
                                                >
                                                    Guardar
                                                </b-button>
                                            </div>
                                        </b-form>
                                    </v-card>
                                </v-col>
                                <v-col cols="2"></v-col>
                            </v-row>
                            >
                        </v-tab-item>
                        <v-tab-item>
                            <v-row>
                                <v-col>
                                    <p class="description">
                                      En Adjudica cuidamos tu privacidad y entendemos que ya no desees ser parte de nuestra plataforma.<br>
                                      Al dar click en "Cerrar cuenta" daremos de baja tu perfil y te eliminaremos de nuestros registros de base de datos.<br>
                                      Cualquier sugerencia siempre será bienvenida a info@adjudica.ai
                                    </p>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="4"></v-col>
                                <v-col cols="4">
                                    <v-card flat>
                                        <button id="kt_submit_exit" type="button"
                                                class="btn btn-warning btn-elevate kt-login__btn-primary kt-spinner--light kt-spinner--right"
                                                @click="dialog = true">
                                            Cerrar cuenta
                                        </button>
                                        <v-dialog v-model="dialog"
                                                  max-width="290">
                                            <v-card>
                                                <v-card-title class="headline">Cerrar cuenta</v-card-title>
                                                <v-card-text>
                                                    ¿Estás seguro que deseas cerrar tu cuenta?
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="green darken-1"
                                                           text
                                                           @click="deleteAccount()">
                                                        Aceptar
                                                    </v-btn>
                                                    <v-btn color="green darken-1"
                                                           text
                                                           @click="dialog = false">
                                                        Cancelar
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-card>
                                        </v-dialog>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>
                    </v-tabs>

                </v-card>
            </v-card>
        </v-app>
    </transition>
</template>

<script>
    import {SET_BREADCRUMB} from "@/store/breadcrumbs.module";
    import storeService from "@/common/jwt.service";
    import {minLength, required, sameAs} from "vuelidate/lib/validators";
    import {validationMixin} from "vuelidate";
    import Swal from "sweetalert2";
    import {UPDATE_PASSWORD} from "@/store/auth.module";
    import service from "@/service/users.service"

    export default {
        name: "Profile",
        mixins: [validationMixin],
        components: {
          userForm :()=> import('../users/UserForm')
        },
        data() {
            return {
                dialog: false,
                infoError: null,
                postPayment: null,
                formPassword: {
                    oldPassword: "",
                    password: "",
                    c_password: ""
                },
                loading: [],

            }
        },
        validations: {
            formPassword: {
                oldPassword: {
                    required
                }, password: {
                    required,
                    minLength: minLength(6)
                },
                c_password: {
                    sameAsPassword: sameAs('password')
                }
            }
        },
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                { title: "Sistema"},{title: "Mi perfil"}
            ]);
            setTimeout(()=> {
                this.loading = false;
            }, 1000);
            this.postPayment = this.$route.params;
        },
        computed: {
            user() {
                return storeService.getUser();
            },
        },
        methods: {
            deleteAccount() {
                this.dialog = false;
                const submitButton = document.getElementById("kt_submit_exit");
                submitButton.classList.add("kt-spinner");
                service.delete(this.user.id).then(() => {
                    submitButton.classList.remove("kt-spinner");
                    Swal.fire({
                        title: "",
                        text: "Cuenta eliminada correctamente",
                        icon: "warning",
                        confirmButtonClass: "btn btn-secondary",
                        preConfirm: () => {
                            this.$router.push({name: "login"})
                        }
                    })
                })
            },

            validateStatePassword(name) {
                const {$dirty, $error} = this.$v.formPassword[name];
                return $dirty ? !$error : null;
            },

            submitPassword: function (e) {
                e.preventDefault();
                this.$v.formPassword.$touch();
                if (this.$v.formPassword.$anyError) {
                    return;
                }
                const passwordactual = this.$v.formPassword.oldPassword.$model;
                const passwordnuevo = this.$v.formPassword.password.$model;
                const c_passwordnuevo = this.$v.formPassword.c_password.$model;
                const submitButton = document.getElementById("kt_submit_p");
                submitButton.classList.add("kt-spinner");

                this.$store.dispatch(UPDATE_PASSWORD, {
                    passwordactual,
                    passwordnuevo,
                    c_passwordnuevo
                }).then((x) => {
                    submitButton.classList.remove("kt-spinner");
                    Swal.fire({
                        title: "",
                        text: x.message,
                        icon: "warning",
                        confirmButtonClass: "btn btn-secondary",
                        preConfirm: () => {
                            if (x.message.includes('correctamente')) {
                                this.$router.push({name: "login"})
                            }
                        }
                    });
                })
            }
        }
    }
</script>

<style scoped>
 .description {
     padding-left: 50px;
     padding-right: 50px;
     padding-top: 50px;
 }
</style>
